.notification-wrapper {
    margin:24px;
    padding:24px;
    background:white;
    border-right: 3px solid #68bb73;
}

.select__control{
    border: none !important;
    border-bottom: 1px solid grey !important;
    border-radius: 0 !important;
    box-shadow: 1px 1px 1px 1px white !important;
}

.select__value-container{
    padding-left: 0px !important;
}

.select__input input {
    padding-bottom: 0px !important;
}

.basic-multi-select {
    margin-top:5px;
    border-radius: 25px;
}

.wash-wrapper {
    margin: 24px;
    padding: 25px;
    background-color: white;
    box-shadow: 1px 0px 8px -2px grey;
    border-radius: 24px;
}

.wash-detail>label {
    font-weight: bold;
}

.wash-detail>p {
    margin-top: 12px;
}

.wash-avatar {
    width: 128px;
    height: 128px;
}

.wash-gallery-photo {
    width: auto;
    height: 128px;
    border-radius: 25px;
    border: 1px solid white;
}

.wash-gallery-photo:hover {
    cursor: pointer;
    border: 1px solid #1DD0DC;
}