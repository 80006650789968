.admin-header-wrapper{
    z-index: 100;
    position: fixed;
    /*height: 80px;*/
    right: 0;
    /*left: 250px; */
    left: 0;
    background: #ffffff;
    box-shadow: 1px 0px 8px -2px grey;
}

.admin-header-wrapper h5 {
    margin-bottom: 0px;
}

.admin-header-wrapper .logout-icon {
    cursor:pointer;
    font-size:30px;
    margin-left:24px;
}

.add-icon{
    cursor:pointer;
    font-size:30px;
}