.list-wrapper {
    margin: 16px;
    padding: 25px;
    background-color: white;
    box-shadow: 1px 0px 8px -2px grey;
}

.list-card-wrapper{
    padding: 12px 24px 12px 24px;
    width: 100%;
   /* height: 70px;*/
    background: white;
    border-bottom: 1px solid #EAE7E7;
    cursor:pointer;
}

.list-card-wrapper:hover{
    background: #f0439f78;
}

.list-card-wrapper.no-active:hover{
    background: #f0439f;
}

.list-card-wrapper a{
    text-decoration: none;
}

.list-card-wrapper.no-active{
    opacity: 0.3;
}

.parameters-list-wrapper {
    height: 80px;
}

.list-search-wrapper{
    max-width: 450px;
    position: relative;
}

.list-search-wrapper input{
    height: 38px;
    margin-bottom: 5px;
    padding-left: 25px;
    font-size: 20px !important;
}

.search-icon {
    position: absolute;
    right: 14px;
    top: 29px;
    font-size: 18px;
    color: #252525;
}

.list-wrapper .card-section .card-label {
    font-size: 13px;
    font-weight: bold;
    color: #232323;
}

.list-wrapper .card-section .card-label-value {
    font-size: 15px;
    color: #232323;
}

.list-wrapper .card-section{
    margin-right: 24px; 
}

.no-element-wrapper{
    text-transform: uppercase;
    color: #909090;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 2px;
}