.login-form-wrapper {
    height:100%;
    background-color: #110F27;
    color: white;
}

.login-form{
    width: 550px;
    padding: 50px 50px 50px 50px;
    background-color: transparent;
    margin: auto 0;
}

.login-form h3{
    text-align: center;
    margin-bottom: 30px;
}

.login-button{
    height:50px;
    width:100%;
}
 
.login-form img {
    display: block;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
}