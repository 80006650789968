html, body, #root, #root>div {
  overflow-x: auto;
  background: #F4F3F3;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
}

.btn {
  border-radius: 25px;
}

label {
  margin-bottom: 0px !important;
}

a, a:hover {
  text-decoration: none !important;
}

.form-group input, .admin-header-wrapper input, .form-group textarea {
  font-size: 14px !important;
  color: black;
  border: 1px solid #DCD6D6;
  border-radius: 25px;
}

.form-group label {
  margin-bottom:4px !important;
}

.form-group select {
  color: black;
  border: 1px solid #DCD6D6;
  border-radius: 25px;
}

.form-control:focus {
  border: 1px solid #1DD0DC;
}

.input-dark {
  border: 1px solid white;
  background-color: #110F27;
  color: white!important;
}

.input-dark:focus {
  border: 2px solid #1DD0DC;
  background-color: #110F27;
  color: white;
}

input.is-error, textarea.is-error, .basic-multi-select.is-error .select__control {
  border: 2px solid #f0439f !important;
}

form {
  width: 100% !important;
}

input:focus, textarea:focus {
  box-shadow: none !important;
  outline: 0 !important;
}

/* PRZYCISKI APLIKACJI */

.btn-primary {
  color: #fff;
  background-color: #f0439f;
  border-color: #f0439f;
}

.btn-primary.focus, .btn-primary:focus, .btn-primary.hover, .btn-primary:hover {
  box-shadow: none !important;
  outline: 0 !important;
  color: #fff !important;
  background-color: #f36ab3 !important;
  border-color: #f36ab3 !important;
}

.btn-dark {
  color: #fff;
  background-color: #110f27;
  border-color: #110f27;
}

.btn-dark.focus, .btn-dark:focus, .btn-dark.hover, .btn-dark:hover {
  box-shadow: none !important;
  outline: 0 !important;
  color: #fff !important;
  background-color: #2d2868 !important;
  border-color: #2d2868 !important;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #27dc16;
  background-color: #27dc16;
}

.admin-right-wrapper {
  margin-top: 80px;
  /*margin-left: 250px;*/
}

.admin-right-wrapper.no-auth {
  margin-top: 0px;
  margin-left: 0px;
}

/* LOADER */

.absolute-loader {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  background: #efefef;
  z-index: 1000 !important;
}

.app-loader {
  z-index: 200;
}

.powered-by {
  position: absolute;
  right: 60px;
  bottom: 60px;
}

/* EDIT FORM SECTION */

.save-wrapper {
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.save-wrapper .save-button .btn {
  height: 40px;
  padding-left: 24px;
  padding-right: 24px;
}


/* radio buttons */

.custom-radio {
  margin-top: 5px;
  margin-right: 10px;
}

/* no element  */

.no-element {
  text-transform: uppercase;
  font-size: 15px;
  color: #151515;
  font-weight: 300;
  letter-spacing: 4px;
}

/* pagination */

.pagination-wrapper {
  margin-bottom: 24px;
  margin-top: 24px;
  width: 100%;
  display: flex;
}

.pagination-wrapper .pagination-navigation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pagination-wrapper .pag-go-prev, .pagination-wrapper .pag-go-next {
  width: 25px;
  height: 25px;
  background: #f0439f;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pagination-wrapper .pag-go-prev svg {
  margin-right: 2px;
}

.pagination-wrapper .pag-go-next svg {
  margin-left: 2px;
}

.pagination-wrapper .pagination-routes, .routes {
  display: flex;
  flex-direction: row;
}

.pagination-wrapper .single-route {
  font-size: 13px;
  font-weight: 500;
  margin-left: 7px;
  margin-right: 7px;
  display: flex;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pagination-wrapper .single-route:hover {
  border: 2px solid #a72fa9;
}

.pagination-wrapper .routes .single-route {
  margin-left: 7px;
  margin-right: 7px;
}

.pagination-wrapper .route-breaker {
  margin-left: 7px;
  margin-right: 7px;
  display: flex;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  justify-content: center;
  align-items: flex-end;
}

.pagination-wrapper .single-route.active {
  border: 2px solid #f0439f;
}

.pagination-wrapper a {
  color: #1d1d1d;
  text-decoration: none;
  background-color: transparent;
}

.error-warpper {
  color: #f0439f;
  margin-top: 7px;
  font-size: 15px;
}

.error-warpper:before {
  content: "ⓘ";
  margin-right: 6px;
}

/* datepicker */

.react-datetime-picker__wrapper {
  width: 200px;
}

/* TOAST */

.toast-wrapper {
  bottom: 0;
  left: 0;
  position: fixed;
  padding: 15px 45px 15px 45px;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100px;
  width: 100%;
  background: #b3b3b3d9;
}

.app-toast {
  padding: 15px 45px 15px 45px;
}

.app-toast span {
  font-size: 15px;
  font-weight: 400;
}

.app-toast.info-toast {
  background: #1d1d1d;
}

.app-toast.alert-toast {
  background: #ca3030;
}

/* sort view */

.sort-wrapper {
  /* position: absolute; */
  margin-top: -11px;
  margin-bottom: 20px;
  width: 300px;
}

.sort-wrapper .select__control {
  background-color: #f3f6ff00 !important;
  box-shadow: none !important;
}

.form-group input[type="file"] {
  border: none;
}



/* RWD - menu mobilne */


.admin-sidebar-wrapper-rwd.toggled .admin-sidebar-wrapper {
  margin-left: 0;
  width: 100vw;
  -webkit-transition: all .25s ease-in-out;
  -moz-transition: all .25s ease-in-out;
  -o-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
}

.admin-sidebar-wrapper-rwd {
  z-index: 102;
}
.menu-burger{
  display: block;
  position: fixed;
  z-index: 104;
  top: 20px;
  left: 20px;
  padding: 14px 12px;
  width: 50px;
  height: 50px;
  background-color:#f36ab3;
  border-radius: 50px;
}
.burger-icon{
  width: 25px!important;
  height: 23px;
}
.admin-sidebar-wrapper-rwd .toggled {
  margin-left: -100vw;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

.modal-content{
  overflow: hidden;
}

.modal-content .search-icon {
  float:right;
  position: relative;
  right:0;
}

/* RWD - mobilne */

@media (max-width: 768px){  
  .user-wrapper .list-wrapper {
    margin: 0!important;
    padding: 0!important;
  }
  .admin-right-wrapper {
    margin-top: 150px;
  }
  .admin-right-wrapper {
    margin-left: 0px!important;
  }
  .admin-sidebar-wrapper{
    width: 100vw !important; 
    margin-left: -100vw;
  }
  .save-button .btn {
    margin-bottom: 10px;
    width: 100%;
  }
  .save-wrapper .save-button .btn {
    padding-left: 14px;
    padding-right: 14px;
  }
  .admin-sidebar-wrapper.toggled{
    margin-left: 0;
  }
  .wash-gallery-photo{
    width: 100%!important;
    height: auto!important;
  }
  .save-wrapper h4{
    width: 100%;
  }
  .pagination-wrapper {
    margin-top: 44px;
  }
  .sort-wrapper{
    width:80vw;
  }
  .justify-content-sm-center{
    justify-content: center;
  }
  .admin-header-wrapper{
    min-height: 152px;
  }
  .list-wrapper .card-section{
    margin-right: 0;
        padding-left: 0;
  }
  .login-form-wrapper{
    width: 100vw;
  }
  .save-button{    
    width: 100%;
    display: flex;
  }
  .admin-header-wrapper h5{
    text-align: center;
  }
/* RWD - ograniczenie kolumn na mobilnym */

  .wash-list div:nth-child(3),
  .wash-list div:nth-child(4),
  .users-list div:nth-child(3),
  .users-list div:nth-child(4),
  .users-list div:nth-child(5){
    display: none !important;
  }
}



/* RWD - bootstrap fix */
.col-12{
  width: 100%!important;
}
.col-8{
 width: 75%!important;
}
.col-7{
 width: 58.33%!important;
}
.col-6{
  width: 50%!important;
}
.col-5{
  width: 41.66%!important;
}
.col-4{
  width: 25%!important;
}
.col-2{
  width: 16.66%!important;
}
@media (min-width: 370px){ /* sm */
  .col-sm-12{
    width: 100%!important;
  }
  .col-sm-8{
   width: 75%!important;
  }
  .col-sm-7{
   width: 58.33%!important;
  }
  .col-sm-6{
    width: 50%!important;
  }
  .col-sm-5{
    width: 41.66%!important;
  }
  .col-sm-4{
    width: 25%!important;
  }
  .col-sm-2{
    width: 16.66%!important;
  }
}

@media (min-width: 768px){ /* md */
  .admin-sidebar-wrapper {
    margin-left: 0 !important;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
  }

  .menu-burger{
    display: none;
  }
  .col-md-12{
    width: 100%!important;
  }
  .col-md-8{
    width: 75%!important;
  }
  .col-md-6{
  width: 50%!important;
  }
  .col-md-5{
    width: 41.66%!important;
  }
  .col-md-4{
    width: 25%!important;
  }
  .col-md-2{
    width: 16.66%!important;
  }
  
  .pagination-wrapper {
    margin-top: 4px;
    position: absolute;
    right: 40px;
  }
}

@media (min-width: 992px){ /* lg */
  
  .col-lg-12{
    width: 100%;
  }
  .col-lg-8{
    width: 75%;
  }
  .col-lg-6{
    width: 50%;
  }
  .col-lg-4{
    width: 25%;
  }
  .col-lg-2{
    width: 16.66%;
  }
}


/* iphone 12 pro max fix ? */

html, body, #root, #root>div {
  min-width: 100vw !important;
}