.user-wrapper {
    margin: 18px;
    padding: 25px;
    background-color: white;
    box-shadow: 1px 0px 8px -2px grey;
    border-radius: 24px;
}

.user-detail>label {
    font-weight: bold;
}

.user-detail>p {
    margin-top: 12px;
}

.list-wrapper {
    box-shadow: none!important;
}