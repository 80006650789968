.autocomplete-input{
    padding: 7px 10px;
    width: 100%;
}

.autocomplete-options-wrapper{
    box-shadow: -1px 4px 10px -6px #ababab;
    position: absolute;
    z-index:200;
    width: 100%;
    max-height: 200px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    border-bottom: 1px solid #f1f1f1;
    border-left: 1px solid #f1f1f1;
    background-color: white;
}

.autocomplete-options-wrapper span{
    cursor: pointer;
    padding: .25rem 1.5rem;
}

.autocomplete-options-wrapper span:hover{
    background: #D1FDF7;
}