.admin-sidebar-wrapper{
    position:fixed;
    width: 16.66%;
    background: #110F27;
}

.admin-sidebar-wrapper nav span{
    padding-left:10px;
}
.admin-sidebar-wrapper nav a span{
    color:white;
}

.admin-sidebar-wrapper nav  {
    margin-top:20px;
}

.admin-sidebar-wrapper nav a {
    margin-bottom:10px;
}

.sidebar-logo{
    width: 100%;
    height: auto;
    background: #68bb73;
}
.img-fluid{
    width: 100%;
}
.sidebar-text {
    font-weight: 700;
    color: #525252;
    font-size: 19px;
}

.nav-menu .menu-row{
    height:55px;
    margin: 10px;
    padding-left: 20px;
}

.nav-menu .menu-row .menu-row-icon{
    width: 80px;
}

.nav-menu .menu-row:hover{
    background: #F045A0;
    cursor: pointer;
}

.nav-menu .menu-row .menu-row-name{
    color: white;
}

.powered-by-sidebar{
    position:absolute;
    bottom:24px;
    right: 24px
}
